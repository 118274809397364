import React, {Fragment} from "react";
import * as UserActions from "../../reducers/authentication/actions"
import {connect} from "react-redux";
import {withRouter} from 'react-router';
//banger components
import Button from "../button/Button";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import Heading from "../heading/Heading";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CheckboxWithLabel from "../Checkbox/CheckboxWithLabel";
import HeadingSeo from '../HeadingSeo';
import Link from 'react-router-dom/Link';
import XRegExp from 'xregexp';

class RegisterForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstname: "",
            lastname: "",
            schoolname: "",
            street: "",
            streetnr: "",
            city: "",
            postal: "",
            email: "",
            countryCode: "",
            country: "",
            currenttime: "",
            ip: "",
            role: "",
            password: "",
            passwordValidation: "",
            incomplete: false,
            loading: false,
            newsletter: false,
            isRegistered: false,
            attemptedRegister: false,
            error: {
                firstname: {valid: null, msg: ""},
                lastname: {valid: null, msg: ""},
                email: {exists:null, valid: null, msg: ""},
                password: {valid: null, msg: ""},
                passwordValidation: {valid: null, msg: ""},
                schoolname: {valid: null, msg: ""},
                street: {valid: null, msg: ""},
                streetnr: {valid: null, msg: ""},
                city: {valid: null, msg: ""},
                postal: {valid: null, msg: ""},
                country: {valid: null, msg: ""}
            },
            roleError: false,
            incompleteFields: false,
        }
    }

    componentDidMount() {
        this._isMounted = true;

        const countryCode = window.localStorage.getItem("usr_country_code");
        const country = window.localStorage.getItem("usr_country_name");
        const city = window.localStorage.getItem("usr_city");
        const currenttime = window.localStorage.getItem("usr_current_time");
        const ip = window.localStorage.getItem("usr_ip");

        this.setState({
            countryCode,
            country,
            city,
            currenttime,
            ip
        });
    }

    componentWillUnmount() {
        console.log("unmount, what to do ");
        this._isMounted = false;

    }

    // register methods
    onRegister = () => {
        this.setState({incompleteFields: false});
        const {
            firstname,
            lastname,
            email,
            password,
            passwordValidation,
            role,
            schoolname,
            street,
            streetnr,
            city,
            postal,
            newsletter,
            countryCode,
            currenttime,
            ip
        } = this.state;
        if (this.validateForm()) {
            this.setState({loading: true, attemptedRegister: true, incompleteFields: false});
            this.props.register(firstname, lastname, role, email, password, passwordValidation, schoolname, street, streetnr, postal, city, newsletter, countryCode, currenttime, ip, this.registerSuccess, this.registerError);
        } else this.setState({incompleteFields: true,loading: false});
    }

    registerSuccess = () => {
        if (this._isMounted) {

            // send newsletter registration
            // set value
            document.getElementById("text3233595").value = this.state.email;
            document.getElementById("newsletterForm").submit();
            console.log("submitted form");
            this.setState({loading: false, isRegistered: true});
        }
        if (this.props.registerSuccessCallback) {
            console.log("callback from registerform");
            this.props.registerSuccessCallback();
        }

    }

    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword});
    };

    handleClickShowPasswordRepeated = () => {
        this.setState({showPasswordRepeated: !this.state.showPasswordRepeated});
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };


    registerError = (err) => {
        this.setState({loading: false, isRegistered: false}, () => {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.errorCode && parseInt(err.response.data.error.errorCode) === 409) {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        email: {exists:true, valid: false, msg: 'E-Mail-Adresse schon vorhanden'}
                    }
                }));
            }
        });
    }

    // validation
    validateFirstname() {
        const {firstname} = this.state;
        if (firstname.length > 0) {
            if (this.validName(firstname)) {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        firstname: {...prevState.error.firstname, valid: true, msg: ""}
                    }
                }));
            } else this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    firstname: {
                        ...prevState.error.firstname,
                        valid: false,
                        msg: "Vorname darf nur gülte Zeichen enthalten."
                    }
                }
            }));
        } else this.setState(prevState => ({
            error: {
                ...prevState.error,
                firstname: {...prevState.error.firstname, valid: null, msg: ""}
            }
        }));
    }

    validateLastname() {
        const {lastname} = this.state;
        if (lastname.length > 0) {
            if (this.validName(lastname)) {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        lastname: {...prevState.error.lastname, valid: true, msg: ""}
                    }
                }));
            } else this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    lastname: {
                        ...prevState.error.lastname,
                        valid: false,
                        msg: "Nachname darf nur gülte Zeichen enthalten."
                    }
                }
            }));
        } else this.setState(prevState => ({
            error: {
                ...prevState.error,
                lastname: {...prevState.error.lastname, valid: null, msg: ""}
            }
        }));
    }

    validateEmail() {
        const {email} = this.state;
        if (email.length > 0) {
            if (this.validEmail(email)) {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        email: {...prevState.error.email, valid: true, msg: ""}
                    }
                }));
            } else this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    email: {...prevState.error.email, valid: false, msg: "Muss gültige E-Mail enthalten."}
                }
            }));
        } else this.setState(prevState => ({
            error: {
                ...prevState.error,
                email: {...prevState.error.email, valid: null, msg: ""}
            }
        }));
    }

    validatePassword() {
        const {password} = this.state;
        if (password.length > 0) {
            if (this.validPassword(password)) {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        password: {...prevState.error.password, valid: true, msg: ""}
                    }
                }));
            } else this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    password: {
                        ...prevState.error.password,
                        valid: false,
                        msg: "Muss Buchstaben und mindestens eine Ziffer enthalten. Länge muss mindestens 6 Zeichen betragen."
                    }
                }
            }));
        } else this.setState(prevState => ({
            error: {
                ...prevState.error,
                password: {...prevState.error.password, valid: null, msg: ""}
            }
        }));
    }

    validatePasswordValidation() {
        const {passwordValidation, password} = this.state;
        if (passwordValidation.length > 0) {
            if (this.validPassword(passwordValidation)) {
                if (passwordValidation === password) {
                    this.setState(prevState => ({
                        error: {
                            ...prevState.error,
                            passwordValidation: {...prevState.error.passwordValidation, valid: true, msg: ""}
                        }
                    }));
                } else this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        passwordValidation: {
                            ...prevState.error.passwordValidation,
                            valid: false,
                            msg: "Muss mit Passwort übereinstimmen."
                        }
                    }
                }));
            } else this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    passwordValidation: {
                        ...prevState.error.passwordValidation,
                        valid: false,
                        msg: "Muss Buchstaben und mindestens eine Ziffer enthalten. Länge muss mindestens 6 Zeichen betragen."
                    }
                }
            }));
        } else this.setState(prevState => ({
            error: {
                ...prevState.error,
                passwordValidation: {...prevState.error.passwordValidation, valid: null, msg: ""}
            }
        }));
    }

    // Schuldaten
    validateSchoolname() {
        const {schoolname} = this.state;
        if (schoolname.length > 0) {
            if (this.validName(schoolname)) {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        schoolname: {...prevState.error.schoolname, valid: true, msg: ""}
                    }
                }));
            } else this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    schoolname: {
                        ...prevState.error.schoolname,
                        valid: false,
                        msg: "Schulname darf nur gülte Zeichen enthalten."
                    }
                }
            }));
        } else this.setState(prevState => ({
            error: {
                ...prevState.error,
                schoolname: {...prevState.error.schoolname, valid: null, msg: ""}
            }
        }));
    }

    validateStreet() {
        const {street} = this.state;
        if (street.length > 0) {
            if (this.validStreet(street)) {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        street: {...prevState.error.street, valid: true, msg: ""}
                    }
                }));
            } else this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    street: {...prevState.error.street, valid: false, msg: "Enthält ungültige Zeichen."}
                }
            }));
        } else this.setState(prevState => ({
            error: {
                ...prevState.error,
                street: {...prevState.error.street, valid: null, msg: ""}
            }
        }));
    }

    validateStreetnr() {
        const {streetnr} = this.state;
        if (streetnr.length > 0) {
            if (this.validStreetnr(streetnr)) {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        streetnr: {...prevState.error.streetnr, valid: true, msg: ""}
                    }
                }));
            } else this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    streetnr: {...prevState.error.streetnr, valid: false, msg: "Ungültige Hausnummer."} // FIXME msg
                }
            }));
        } else this.setState(prevState => ({
            error: {
                ...prevState.error,
                streetnr: {...prevState.error.streetnr, valid: null, msg: ""}
            }
        }));
    }

    validateCity() {
        const {city} = this.state;
        if (city.length > 0) {
            if (this.validCity(city)) {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        city: {...prevState.error.city, valid: true, msg: ""}
                    }
                }));
            } else this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    city: {...prevState.error.city, valid: false, msg: "Enthält ungültige Zeichen."}
                }
            }));
        } else this.setState(prevState => ({
            error: {
                ...prevState.error,
                city: {...prevState.error.city, valid: null, msg: ""}
            }
        }));
    }

    validatePostal() {
        const {postal} = this.state;
        if (postal.length > 0) {
            if (this.validPostal(postal)) {
                this.setState(prevState => ({
                    error: {
                        ...prevState.error,
                        postal: {...prevState.error.postal, valid: true, msg: ""}
                    }
                }));
            } else this.setState(prevState => ({
                error: {
                    ...prevState.error,
                    postal: {
                        ...prevState.error.postal,
                        valid: false,
                        msg: "Ungültige Postleitzahl. Nur Ziffern erlaubt."
                    }
                }
            }));
        } else this.setState(prevState => ({
            error: {
                ...prevState.error,
                postal: {...prevState.error.postal, valid: null, msg: ""}
            }
        }));
    }


    validName = (pw) => {
        var re = XRegExp('[^-.\\s\\p{L}]');// matches everything that is not a unicode letter or . or whitespace!
        return !re.test(pw);
    }

    validEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    validPassword = (pw) => {
        //var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!§$%&]{6,}$/;
        var re = /^(?=.*\d)[^ ]{6,}$/;
        // console.log(pw, re.test(pw));
        return re.test(pw);
    }

    validStreet = (pw) => {
        var re = XRegExp('[^-.\\s\\p{L}]');// matches everything that is not a unicode letter or . or whitespace!
        return !re.test(pw);
    }

    validStreetnr = (pw) => {
        var re = /.+/; // matches any
        return re.test(pw); // returns true if matches, means input is valid
    }

    validCity = (pw) => {
        // var re = XRegExp('[^-.\\s\\p{L}]');// matches everything that is not a unicode letter or . or whitespace!
        return true; // everthing allowed ....
    }

    validPostal = (pw) => {
        var re = /\D+/; // matches any non digit
        return !re.test(pw); // returns true if matches, means input is invalid so retrun false
    }

    validCountry = (country) => {
        return true;
    }


    validateForm = () => {

        /*
            {label: "Schüler/in", value: "schüler"},
            {label: "Student/in", value: "student"},
            {label: "Referendar/in", value: "referendar"}, // benötigt noch den schulnamen
            {label: "Lehrer/in", value: "lehrer"}, // benötigt noch den schulnamen
            {label: "Lektüren-Interessiert", value: "lektuereninteressiert"},
            {label: "Erziehungsberechtigt", value: "eltern"},
         */

        const {error, role} = this.state;
        let valid = true;
        if (!role.length > 0) {
            this.setState({roleError: true});
        } else {
            this.setState({roleError: false});
        }
        if (role === "referendar" || role === "lehrer") {
            let keys = Object.keys(error);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                if (key === "country") break;
                if (error[key].valid === null) {
                    error[key].msg = "Dieses Feld muss ausgefüllt werden.";
                    this.setState({error: error});
                    valid = false;
                }
                if (error[key].valid !== true) valid = false;
            }
        } else {
            let keys = Object.keys(error);
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                if (key === "schoolname" || key === "country") break;
                if (error[key].valid === null) {
                    error[key].msg = "Dieses Feld muss ausgefüllt werden.";
                    this.setState({error: error});
                    valid = false;
                }
                if (error[key].valid !== true) valid = false;
            }
        }

        return valid;
    }

    render() {
        const defaultInput = {
            placeholder: 'E-Mail',
            label: 'E-Mail',
            type: 'text'
        };

        const defaultDropdown = {
            label: 'Du bist...',
            options: [
                {label: "Schüler/in", value: "schüler"},
                {label: "Student/in", value: "student"},
                {label: "Referendar/in", value: "referendar"},
                {label: "Lehrer/in", value: "lehrer"},
                {label: "Lektüren-Interessiert", value: "lektuereninteressiert"},
                {label: "Erziehungsberechtigt", value: "eltern"},
            ],
            name: 'dropDownRole',
            id: 'roleDrop'
        }

        const defaultCheckbox = {
            label: 'Newsletter abonnieren',
            required: true
        }

        const adorn = (<InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={this.handleClickShowPassword}
                onMouseDown={this.handleMouseDownPassword}
            >
                {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
            </IconButton>
        </InputAdornment>);

        const adornRepeat = (<InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility" p
                onClick={this.handleClickShowPasswordRepeated}
                onMouseDown={this.handleMouseDownPassword}
            >
                {this.state.showPasswordRepeated ? <Visibility/> : <VisibilityOff/>}
            </IconButton>
        </InputAdornment>);

        const changeCheckbox = (e) => {
            this.setState({newsletter: e.target.checked})
        }

        const actionsCheckbox = {
            changeHandler: changeCheckbox
        };


        if (this.state.isRegistered && !this.state.loading) {
            return (<div className="main-content-container container-fluid">
                <div className="row pt-5" style={{minHeight: "100%"}}>
                    <div className="col-12">
                        <Heading type="secondary">Registrierung erfolgreich</Heading>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-6 w-50">
                        <div style={{fontSize: "1.4em"}}>
                            Registrierung erfolgreich!
                            Herzlich willkommen im Club der Lektüren-Profis!
                            Du bist nun erfolgreich registriert und hast eine Bestätigung per Mail erhalten.
                            Es kann also losgehen – viel Spaß beim Stöbern!
                        </div>
                    </div>
                </div>
            </div>)
        }

        return (
            <div className="container-fluid">
                <div className="row pt-3 pt-md-5">
                    <div className="col-12">
                        <HeadingSeo type="secondary" headingPriority={1}>Jetzt Registrieren</HeadingSeo>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-5">
                        <Input name={"firstname"} input={{
                            ...defaultInput,
                            label: 'Vorname',
                            type: "name",
                            required: true,
                            placeholder: "Vorname"
                        }} valid={this.state.error.firstname.valid} changeHandler={(e) => {
                            this.setState({firstname: e.target.value}, () => this.validateFirstname());
                        }}/>
                        {!this.state.error.firstname.valid && (
                            <div style={{color: "red", lineHeight: "1.15em"}}>{this.state.error.firstname.msg}</div>)}
                    </div>
                    <div className="col-12 col-md-5">
                        <Input name={"lastname"} input={{
                            ...defaultInput,
                            label: 'Nachname',
                            type: "name",
                            required: true,
                            placeholder: "Nachname"
                        }} valid={this.state.error.lastname.valid}
                               changeHandler={(e) => this.setState({lastname: e.target.value}, () => this.validateLastname())}/>
                        {!this.state.error.lastname.valid && (
                            <div style={{color: "red", lineHeight: "1.15em"}}>{this.state.error.lastname.msg}</div>)}
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-5">
                        <div>
                            <Dropdown name="roleDropdown" dropdown={defaultDropdown} onChange={(e) => {
                                this.setState({role: e.target.value, roleError: false})
                            }}/>
                        </div>
                        {this.state.roleError && <div style={{color: "red"}}>Rolle muss ausgewählt werden</div>}
                    </div>
                    {(this.state.role === "lehrer" || this.state.role === "referendar") && (
                        <div className="col-12 col-md-5">
                            <Input name={"schoolname"} input={{
                                ...defaultInput,
                                label: 'Name der Schule',
                                type: "name",
                                required: true,
                                placeholder: "Schulname"
                            }} valid={this.state.error.schoolname.valid}
                                   changeHandler={(e) => this.setState({schoolname: e.target.value}, () => {
                                       this.validateSchoolname()
                                   })}/>
                            {!this.state.error.schoolname.valid && (<div
                                style={{color: "red", lineHeight: "1.15em"}}>{this.state.error.schoolname.msg}</div>)}
                        </div>)}
                </div>

                <Fragment>
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <Input name={"street"} input={{
                                ...defaultInput,
                                label: 'Straße',
                                type: "street",
                                required: true,
                                placeholder: "Straße"
                            }} valid={this.state.error.street.valid}
                                   changeHandler={(e) => this.setState({street: e.target.value}, () => {
                                       this.validateStreet()
                                   })}/>
                            {!this.state.error.street.valid && (
                                <div style={{color: "red", lineHeight: "1.15em"}}>{this.state.error.street.msg}</div>)}
                        </div>
                        <div className="col-12 col-md-5">
                            <Input name={"streetnr"} input={{
                                ...defaultInput,
                                label: 'Hausnummer',
                                type: "streetnr",
                                required: true,
                                placeholder: "Hausnummer"
                            }} valid={this.state.error.streetnr.valid}
                                   changeHandler={(e) => this.setState({streetnr: e.target.value}, () => {
                                       this.validateStreetnr()
                                   })}/>
                            {!this.state.error.streetnr.valid && (<div
                                style={{color: "red", lineHeight: "1.15em"}}>{this.state.error.streetnr.msg}</div>)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <Input name={"plz"} input={{
                                ...defaultInput,
                                label: 'PLZ',
                                type: "plz",
                                required: true,
                                maxlength: 5,
                                placeholder: "PLZ"
                            }} valid={this.state.error.postal.valid}
                                   changeHandler={(e) => this.setState({postal: e.target.value}, () => {
                                       this.validatePostal()
                                   })}/>
                            {!this.state.error.postal.valid && (
                                <div style={{color: "red", lineHeight: "1.15em"}}>{this.state.error.postal.msg}</div>)}
                        </div>
                        <div className="col-12 col-md-5">
                            <Input name={"city"} input={{
                                ...defaultInput,
                                label: 'Stadt',
                                type: "city",
                                required: true,
                                placeholder: "Stadt"
                            }} valid={this.state.error.city.valid}
                                   changeHandler={(e) => this.setState({city: e.target.value}, () => {
                                       this.validateCity()
                                   })}/>
                            {!this.state.error.city.valid && (
                                <div style={{color: "red", lineHeight: "1.15em"}}>{this.state.error.city.msg}</div>)}
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <div
                                className="MuiFormControl-root MuiTextField-root Input-root-1 MuiFormControl-marginNormal MuiFormControl-fullWidth">
                                <label
                                    className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
                                    data-shrink="true">
                                    Land
                                </label>
                                <div
                                    className="MuiInputBase-root MuiInput-root MuiInput-underline Mui-disabled Mui-disabled MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
                                    <input aria-invalid="false"
                                           className="MuiInputBase-input MuiInput-input Mui-disabled Mui-disabled"
                                           disabled="" type="text" value={this.state.country}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>


                <div className="row">
                    <div className="col-12 col-md-5">
                        <Input name={"email"} input={{
                            ...defaultInput,
                            label: 'E-Mail',
                            type: "email",
                            required: true,
                            placeholder: "E-Mail"
                        }} valid={this.state.error.email.valid}
                               changeHandler={(e) => this.setState({email: e.target.value}, () => this.validateEmail())}/>
                        {!this.state.error.email.valid && (
                            <div style={{color: "red", lineHeight: "1.15em"}}>{this.state.error.email.msg}</div>)}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-5">
                        <Input name={"password"} input={{
                            ...defaultInput,
                            label: 'Passwort',
                            required: true,
                            placeholder: "Passwort",
                            type: this.state.showPassword ? 'text' : 'password'
                        }} showpw={adorn} valid={this.state.error.password.valid}
                               changeHandler={(e) => this.setState({password: e.target.value}, () => {
                                   this.validatePassword()
                               })}/>
                        {!this.state.error.password.valid && (
                            <div style={{color: "red", lineHeight: "1.15em"}}>{this.state.error.password.msg}</div>)}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-5">
                        <Input name={"passwordValidation"} showpw={adornRepeat} input={{
                            ...defaultInput,
                            label: 'Passwort wiederholen',
                            required: true,
                            placeholder: "Passwort wiederholen",
                            type: this.state.showPasswordRepeated ? 'text' : 'password'
                        }} valid={this.state.error.passwordValidation.valid}
                               changeHandler={(e) => this.setState({passwordValidation: e.target.value}, () => {
                                   this.validatePasswordValidation()
                               })}/>
                        {!this.state.error.passwordValidation.valid && (<div style={{
                            color: "red",
                            lineHeight: "1.15em"
                        }}>{this.state.error.passwordValidation.msg}</div>)}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-8 mt-3 mt-md-5">
                        <Heading type="secondary">Schon für unseren Newsletter angemeldet?</Heading>
                    </div>
                    <div className="col-12 col-md-8 mt-md-2">

                        <div>
                            Mit der Auswahl von „Newsletter abonnieren“ erkläre ich mich mit der Verarbeitung meiner
                            E-MailAdresse zum Versand eines Newsletters durch den Bange Verlag
                            gemäß <Link to={"/datenschutz"}>Datenschutzerklärung</Link> einverstanden. Meine
                            Einwilligung
                            kann ich für die Zukunft jederzeit kostenlos widerrufen.
                        </div>
                    </div>
                    <div className="col-12 mt-5 mt-md-0">
                        <CheckboxWithLabel inputName={"newsletter"} checkbox={defaultCheckbox} {...actionsCheckbox} />
                    </div>
                </div>
                {this.state.incomplete && (
                    <div className="row">
                        <div className="col-12"
                             style={{fontSize: "1.15em", lineHeight: "1.15em", color: "red", margin: "10px 0px"}}>
                            {this.state.msg}
                        </div>
                    </div>
                )}
                <div className="row my-3">
                    <div className="col-12">
                        {this.state.incompleteFields &&
                            <div className="mb-2" style={{color: "red", fontSize: "1.15em", lineHeight: "1.15em"}}>
                                Alle erforderlichen Felder müssen korrekt ausgefüllt werden.
                            </div>
                        }
                        {(!this.state.attemptedRegister || !this.state.isRegistered) &&
                            <Button loading={this.state.loading}
                                    onClick={this.onRegister}
                                    colorScheme="classic"
                                    title="Registrieren" size="medium">
                                Registrieren
                            </Button>}
                        {this.state.attemptedRegister && !this.state.isRegistered &&
                            <div className="py-3"
                                 style={{
                                     color: "red",
                                     fontSize: "1.15em",
                                     lineHeight: "1.15em"
                                 }}>
                                <div>
                                    {this.state.error.email.msg === '' ?
                                        'Registrierung nicht erfolgreich. Bitte erneut versuchen' :
                                        this.state.error.email.msg
                                    }
                                    {this.state.error.email.exists &&
                                        <Fragment>
                                            <br />
                                            <span style={{color: "red", textDecoration: "underline"}} onClick={() => {
                                                window.scrollTo(0,0);
                                                document.getElementsByClassName('loginBtn')[0].click()
                                            }
                                            }>
                                                Zum Login
                                            </span>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        }
                        {!this.state.loading && this.state.isRegistered &&
                            <div className="d-inline ml-2"
                                 style={{
                                     color: "red",
                                     fontSize: "1.15em",
                                     lineHeight: "1.15em"
                                 }}>
                                    Registrierung erfolgreich
                            </div>
                        }
                    </div>
                </div>
                <div style={{display: "none"}}>
                    <iframe name="dummyframe" id="dummyframe" title="mydummyframe"></iframe>
                    <form id={"newsletterForm"} className="layout_form cr_form cr_font"
                          action="https://eu.cleverreach.com/f/10202-143200/wcs/" method="post" target="dummyframe"
                          onSubmit={() => {
                              return false
                          }}>
                        <div className="cr_body cr_page cr_font formbox">
                            <div className="non_sortable" style={{textAlign: 'left'}}>

                            </div>

                            <div className="editable_content email-input-container"
                                 style={{textAlign: 'left', margin: "0px auto 10px auto"}}>
                                <div id="3233595" rel="email" className="cr_ipe_item ui-sortable musthave"
                                     style={{marginBottom: 'px'}}>
                                    <Input name="email" input={{label: "E-Mail", id: "text3233595", type: "text"}}
                                           changeHandler={(e) => {
                                           }}/>
                                </div>
                                <div id="3233597" rel="button" className="cr_ipe_item ui-sortable submit_container"
                                     style={{textAlign: 'center', marginTop: 15, marginBottom: "px"}}>
                                    <button type="submit" className="cr_button button classic mediumButton">Jetzt
                                        anmelden
                                    </button>

                                </div>
                            </div>

                            <noscript><a href="http://www.cleverreach.de">www.CleverReach.de</a></noscript>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    register: (firstname, lastname, role, email, password, passwordValidation, schoolname, street, streetnr, postal, city, newsletter, countryCode, currenttime, ip, successCB, errorCB) => dispatch(UserActions.register(firstname, lastname, role, email, password, passwordValidation, schoolname, street, streetnr, postal, city, newsletter, countryCode, currenttime, ip, successCB, errorCB)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterForm));